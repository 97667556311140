import React, { Component } from "react";

import PropTypes from "prop-types";

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";

import Modal from "components/Modal";

const sparePartStatusWrapper = {
  sparePartStatusModal: {
    width: 569,
    height: 430,
  },
  modalTitle: {
    textAlign: "center",
    padding: "37px 0px",
  },
  radioButtonGroup: {
    width: 420,
    height: 170,
    border: "1px solid #DBDFE8",
    borderRadius: 8,
    padding: 24,
    marginLeft: 45,
  },
  radioButtonTitle: {
    paddingBottom: 16,
    color: "#8795B3",
  },
  buttonGroup: {
    textAlign: "center",
    paddingTop: 40,
  },
  cancelButton: {
    color: "#8795B3",
    backgroundColor: "unset",
    boxShadow: "unset",
    fontSize: 16,
  },
  buttonQuoteAccepted: {
    background: "#FCD660",
    color: "#2B3B5C",
    boxShadow: "none",
    fontSize: 16,
    marginLeft: 16,
    "&:hover": {
      backgroundColor: "#3b44cb",
    },
  },
};
const options = [
  { value: "retrieved", label: "Récupérée" },
  { value: "abandoned", label: "Abandonnée" },
];

export class SparePartStatusModal extends Component {
  static propTypes = {
    isSparePartStatusModalOpen: PropTypes.bool,
    handleCloseSparePartStatusModal: PropTypes.func,
    handleValidateSparePartStatus: PropTypes.func,
    sparePartSelected: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      radioChoice: "retrieved",
    };
  }

  handleRadioChange = (event) => {
    this.setState({ radioChoice: event.target.value });
  };

  handleValidateSparePartStatus = () => {
    this.props.handleValidateSparePartStatus(this.props.sparePartSelected, this.state.radioChoice);
  };

  render() {
    const { classes } = this.props;
    return (
      <Modal
        modalClassName={`modal-small-content ${classes.sparePartStatusModal}`}
        isOpen={this.props.isSparePartStatusModalOpen}
        handleCloseModal={this.props.handleCloseSparePartStatusModal}
      >
        <Typography className={classes.modalTitle} variant="h2">
          État de la pièce
        </Typography>
        <div className={classes.radioButtonGroup}>
          <Typography className={classes.radioButtonTitle} variant="subtitle2">
            {this.props.sparePartSelected ? (
              <span>
                {this.props.sparePartSelected.piece_name} - {this.props.sparePartSelected.aswo_ref}
              </span>
            ) : null}
          </Typography>
          <RadioGroup onChange={this.handleRadioChange} value={this.state.radioChoice}>
            {options.map((templateOption, index) => (
              <FormControlLabel
                key={index}
                value={templateOption.value}
                control={<Radio />}
                label={templateOption.label}
                className={classes.radioButtonLabel}
                color="secondary"
                variant="dark"
              />
            ))}
          </RadioGroup>
        </div>
        <div className={classes.buttonGroup}>
          <Button
            id="validate-spare-part-status"
            variant="contained"
            color="secondary"
            onClick={this.handleValidateSparePartStatus}
          >
            Supprimer la pièce
          </Button>
          <Button
            id="cancel-spare-part-status"
            variant="contained"
            className={classes.cancelButton}
            onClick={this.props.handleCloseSparePartStatusModal}
          >
            Annuler
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withStyles(sparePartStatusWrapper)(SparePartStatusModal);
