import React from "react";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
// UI
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";

//Types
import { QuoteElement } from "types/quote-element";

import { quoteElementList } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/QuoteHelper.js";
// Services
import { getQuoteElementProperty } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/getQuoteElementProperty.js";

//Utils
import { formatPriceCompact } from "utils/formatting";

type DefaultElementDisplayProps = {
  element: QuoteElement;
  remove: (content: Record<string, any>) => void;
  index: number;
};

const DefaultElementDisplay = ({ element, remove, index }: DefaultElementDisplayProps) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
      <Typography variant="body1">{getQuoteElementProperty(element.element_type, quoteElementList, "name")}</Typography>
      <Box display="flex" alignItems="center">
        <Typography variant="body1">
          {element.amount_withtax ? formatPriceCompact(element.amount_withtax) : ""}
        </Typography>
        {remove && (
          <IconButton onClick={() => remove(element)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default DefaultElementDisplay;
