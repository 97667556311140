import { getSparePartPriceWithTax } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/sparePartPriceCalculator.js";

import { getPricePretax } from "utils/price";

function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

/**
 * Remove digits prefix
 * Capitalize
 * Limit 64 characters
 */
export function formatAswoName(name) {
  name = name.replace(/^[0-9\s]*/g, "");
  name = capitalize(name);
  return name.slice(0, 64 - name.length);
}

/**
 * Removes "#"
 * Returns reference to uppercase
 * @param {string} reference
 * @returns string
 */
export const formatAswoRef = (reference) => {
  if (!reference) {
    return "";
  }
  return reference.replace("#", "").toUpperCase();
};

/**
 * Return the formatted spare part data given the ASWO's API's response, and the spare part custom values and context
 * @param {object} data - the returned spare part data from ASWO
 * @param {string} supplier - the spare part supplier
 * @param {string} spareAswoRef - the spare part ref
 * @param {string} spareName - the spare part name
 * @param {int} customerFile - the customer file id
 * @param {bool} isInMurfyStock - true if in murfy store
 * @param {int} sparePartsToBeAdded - the number of recently added spare parts + the ones to be added
 */
export function formatSparePartData({
  skuId,
  supplier,
  spareAswoRef,
  spareName,
  customerFile,
  isInMurfyStock,
  sparePartsToBeAdded,
  isCorrespondance,
  stockPrice,
  productId,
  isSecondHandAvailable = false,
  data = null,
}) {
  const getAmountWithtaxInCents = () => {
    if (stockPrice) {
      return getSparePartPriceWithTax(stockPrice, 1, sparePartsToBeAdded) * 100;
    } else if (data) {
      return getSparePartPriceWithTax(parseFloat(data.Stueckpreis), 1, sparePartsToBeAdded) * 100;
    }
    return;
  };
  const amountWithtaxInCents = getAmountWithtaxInCents();
  const amountPretaxInCents = amountWithtaxInCents ? getPricePretax(amountWithtaxInCents) : null;

  if (data || isInMurfyStock) {
    const name = data ? formatAswoName(data.Artikelbezeichnung) : spareName;
    return {
      home_repair_product: productId,
      admin_comments: null,
      amount_pretax: amountPretaxInCents,
      amount_withtax: amountWithtaxInCents,
      base_price: stockPrice * 100 || data.Stueckpreis * 100,
      customer_file: customerFile,
      demand_item: {
        aswo_announced_delay: data ? data.maxliefertage : null,
        aswo_verbose_delay: data ? data.Lieferzeit : null,
        is_in_murfy_stock: isInMurfyStock,
        price_pretax: data ? parseInt(parseFloat(data.Stueckpreis) * 100) : stockPrice * 100,
        sku: skuId,
      },
      element_type: "spare_part",
      name,
      quote_billing: null,
      sku_model: {
        designation: name,
        sku_reference: supplier + "_" + spareAswoRef,
        supplier: supplier,
        supplier_reference: spareAswoRef,
      },
      spare_part: {
        aswo_ref: spareAswoRef,
        availability: "",
        correspondance: isCorrespondance,
        piece_name: name,
        price_pretax: data ? parseInt(parseFloat(data.Stueckpreis) * 100) : stockPrice * 100,
        quantity: 1,
        second_hand_available: isSecondHandAvailable,
        state: "unaccepted_quote",
      },
    };
  } else {
    return {
      home_repair_product: productId,
      admin_comments: null,
      amount_pretax: null,
      amount_withtax: null,
      customer_file: customerFile,
      demand_item: {
        aswo_announced_delay: null,
        aswo_verbose_delay: null,
        is_in_murfy_stock: isInMurfyStock,
        price_pretax: null,
        sku: skuId,
      },
      element_type: "spare_part",
      name: spareName,
      quote_billing: null,
      sku_model: {
        designation: spareName,
        supplier: supplier,
        supplier_reference: spareAswoRef,
        sku_reference: supplier + "_" + spareAswoRef,
      },
      spare_part: {
        aswo_ref: null,
        availability: "",
        piece_name: spareName,
        price_pretax: null,
        quantity: 1,
        second_hand_available: isSecondHandAvailable,
        state: "unaccepted_quote",
      },
    };
  }
}

function getAswoVerboseDelay(demandItem) {
  switch (demandItem.aswo_announced_delay) {
    case 0:
      return "En stock chez ASWO";
    case null:
      return "Référence manquante ou inexacte";
    case "-1":
      return "Pas d'information sur le stock ASWO";
    default:
      // NB: aswo_verbose_delay is only available for new elements as we do not
      // store it in database and do not request it again for all past spare parts.
      return demandItem.aswo_verbose_delay || `Disponible sous ${demandItem.aswo_announced_delay} jours`;
  }
}

export function getAvailabilityText(demandItem, supplier = "ASWO") {
  if (!demandItem) return null;

  switch (demandItem.status) {
    case "waiting_for_sku":
      return `Déjà commandée (${demandItem.aswo_verbose_delay})`;
    case "reserved":
      return "En stock à l'atelier (réservée)";
    case "affected":
      return "En stock à l'atelier (affectée)";
    case "packed":
      return "En stock à l'atelier (colisée)";
    case "loss_declared":
      return demandItem.is_in_murfy_stock
        ? "Pièce perdue mais en stock à l'atelier (le coup de bol !)"
        : `Pièce perdue: ${getAswoVerboseDelay(demandItem)}`;
    case "emitted":
    default:
      if (supplier === "ASWO") {
        return demandItem.is_in_murfy_stock ? "En stock à l'atelier" : getAswoVerboseDelay(demandItem);
      }
      return demandItem.is_in_murfy_stock ? "En stock à l'atelier" : "Non disponible à l'atelier";
  }
}
