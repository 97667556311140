// React imports
import React from "react";

// MUI
import { Box, IconButton, Typography, useTheme } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { quoteElementList } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/QuoteHelper.js";
// Services
import { getQuoteElementProperty } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/getQuoteElementProperty.js";

// Utils
import { formatPriceCompact } from "utils/formatting";

const ReductionCode = ({ isModifiable = false, element, isDeletable, removeElementInQuote, index }) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="space-between" p={1}>
      <Box my="auto" mx={0} color={theme.palette.secondary.grey900}>
        <Typography variant="body1" color="inherit">
          {getQuoteElementProperty(element.element_type, quoteElementList, "name")}
          &nbsp;- {element.coupon.code}
        </Typography>
      </Box>
      <Box display="inline-flex" my="auto">
        <Typography variant="body1">{formatPriceCompact(element.amount_withtax)}</Typography>
        {isDeletable && isModifiable && (
          <Box color={theme.palette.secondary.grey900} ml="3px">
            <IconButton id="delete-icon" color="inherit" onClick={() => removeElementInQuote(element)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ReductionCode;
