// React imports
import React from "react";

// MUI
import { Box, IconButton, Typography, styled } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { QuoteElement } from "types/quote-element";

import { quoteElementList } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/QuoteHelper.js";
// Services
import { getQuoteElementProperty } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/getQuoteElementProperty.js";

// Utils
import { formatPriceCompact } from "utils/formatting";

type AfterSalesPackageProps = {
  isModifiable: boolean;
  isDeletable: boolean;
  removeElementInQuote: (cotent: Record<string, any>) => void;
  element: QuoteElement;
  index: number;
};

const StyledBox = styled(Box)(({ theme }: any) => ({
  color: theme.palette.secondary.grey900,
}));

const AfterSalesPackage: React.FC<AfterSalesPackageProps> = ({
  isModifiable = false,
  element,
  isDeletable,
  removeElementInQuote,
  index,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" p={1}>
      <StyledBox my="auto" mx={0}>
        <Typography variant="body1" color="inherit">
          {getQuoteElementProperty(element.element_type, quoteElementList, "name")}
        </Typography>
      </StyledBox>
      <Box display="inline-flex" my="auto">
        <Typography variant="body1">{formatPriceCompact(element.amount_withtax)}</Typography>
        {isDeletable && isModifiable && (
          <StyledBox ml="3px">
            <IconButton id="delete-icon" color="inherit" onClick={() => removeElementInQuote(element)}>
              <DeleteIcon />
            </IconButton>
          </StyledBox>
        )}
      </Box>
    </Box>
  );
};

export default AfterSalesPackage;
